/* Import here all global assets */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'pathseg'

// Stylesheets
import '$scss/common.scss'

//Why we do it
import '@images/why_we_do_it_bg.png'

//AI
import '@images/ai-background-hero.png'
import '@images/ai-background-hero-mobile.png'
import '@images/data-masters-logo.png'
import '@images/ultime_blog.png'

//Why we are
import '@images/who_we_are_bg.png'

//Where we are
import '@images/where_we_are_bg.png'
import '@images/where_01.png'
import '@images/where_02.png'
import '@images/where_03.png'

//Get in touch
import '@images/get_in_touch_header_bg.jpg'

import '@images/messenger_icon.svg'

//News
import '@images/news_bg.jpg'

import '@images/play.svg'
import '@images/group.png'
import '@images/project_detail.png'
import '@images/group.png'


//Placeholder
import '@images/placeholder.svg'
import '@images/placeholder2.png'
import '@images/placeholder3.png'
import '@images/placeholder4.jpg'
import '@images/placeholder5.jpg'
import '@images/we_work_with_1.svg'
import '@images/we_work_with_2.svg'
import '@images/we_work_with_3.svg'

//Services Icons
import '@images/icon_chart_area.svg'


//Tecnologies
import '@images/logos/css3.svg'
import '@images/logos/html5.svg'
import '@images/logos/javascript.svg'
import '@images/logos/mysql.svg'
import '@images/logos/node.svg'
import '@images/logos/php.svg'
import '@images/logos/python.svg'
